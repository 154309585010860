<template>
  <user-settings-layout>
    <template v-slot:title> {{ $t("settings.user.security.gdpr.title") }} </template>
    <template v-slot:content>
      <div class="space-y6">
        <div class="bg-white shadow rounded-md">
          <div class="border-b-2">
            <div class="py-4 px-5">
              <h3 class="text-lg leading-6 font-bold text-gray-900">{{ $t("settings.user.security.gdpr.sub-title") }}</h3>
              <p class="mt-1 text-xs text-gray-500">
                {{ $t("settings.user.security.gdpr.body-text") }}
              </p>
            </div>
          </div>
          <div class="py-4 px-5">
            <div class="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
              <span class="rounded-lg inline-flex p-3 bg-blue-50 text-blue-700">
                <!-- Heroicon name: outline/shield-check -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </span>
              <div class="mt-4">
                <h3 class="text-lg font-bold">
                  <a href="#" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    {{ $t("settings.user.security.gdpr.gdpr-link-title") }}
                  </a>
                </h3>
                <p class="mt-2 text-xs font-light text-gray-400 group-hover:text-gray-600">{{ $t("settings.user.security.gdpr.gdpr-link-body-text") }}</p>
              </div>
              <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </user-settings-layout>
</template>

<script>
import UserSettingsLayout from "@/layouts/UserSettings.vue";
export default {
  components: {
    UserSettingsLayout,
  },
};
</script>

<style></style>
